import React, { useState } from 'react';
import './WhiteAnteaterQuiz.css';

const WhiteAnteaterQuiz: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    setShowResult(true);
    if (selectedOption === 'correct') {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <div className='quiz-container'>
      <h2>Quick Quiz on White Anteaters!</h2>
      <div className='question'>What is the primary diet of White Anteaters?</div>
      <ul className='options'>
        <li className='option'>
          <label>
            <input type='radio' value='correct' name='quiz' onChange={handleOptionChange} />
            Ants and termites
          </label>
        </li>
        <li className='option'>
          <label>
            <input type='radio' value='wrong' name='quiz' onChange={handleOptionChange} />
            Fruits
          </label>
        </li>
        <li className='option'>
          <label>
            <input type='radio' value='wrong' name='quiz' onChange={handleOptionChange} />
            Leaves
          </label>
        </li>
        <li className='option'>
          <label>
            <input type='radio' value='wrong' name='quiz' onChange={handleOptionChange} />
            Small mammals
          </label>
        </li>
      </ul>
      <button className='submit-button' onClick={handleSubmit}>Submit</button>
      {showResult && <div className='result'>{isCorrect ? 'Correct!' : 'Sorry, that is incorrect.'}</div>}
    </div>
  );
};

export default WhiteAnteaterQuiz;
// Enhanced BattleRoyale component
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './BattleRoyale.css';

const BattleRoyale = () => {
  const [players, setPlayers] = useState(10);
  const [gameStatus, setGameStatus] = useState('Waiting');
  const [positions, setPositions] = useState(Array.from({ length: 10 }, (_, i) => i + 1));
  const [winner, setWinner] = useState<string | null>(null);
  const [scores, setScores] = useState<{ [key: string]: number }>({});
  const [round, setRound] = useState(1);

  useEffect(() => {
    const initializeScores = () => {
      const initialScores: { [key: string]: number } = {};
      for (let i = 1; i <= players; i++) {
        initialScores[`Player ${i}`] = 0;
      }
      setScores(initialScores);
    };
    initializeScores();
  }, [players]);

  const handlePlayersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPlayerCount = Number(event.target.value);
    setPlayers(newPlayerCount);
    setPositions(Array.from({ length: newPlayerCount }, (_, i) => i + 1));
  };

  const shufflePositions = () => {
    let shuffled = positions.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    setPositions(shuffled);
  };

  const startGame = () => {
    setGameStatus('Running');
    shufflePositions();
    setWinner(null);
    setTimeout(() => {
      setGameStatus('Finished');
      const winningPlayer = `Player ${positions[0]}`;
      setWinner(winningPlayer);
      setScores((prevScores) => ({
        ...prevScores,
        [winningPlayer]: prevScores[winningPlayer] + 1,
      }));
      setRound((prevRound) => prevRound + 1);
    }, 3000); // Mock game duration
  };

  useEffect(() => {
    if (gameStatus === 'Finished' && winner) {
      const colors = ['#f00', '#0f0', '#00f', '#ff0', '#0ff', '#f0f'];
      let colorIndex = 0;
      const intervalId = setInterval(() => {
        document.body.style.backgroundColor = colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length;
      }, 500);
      return () => clearInterval(intervalId);
    } else {
      document.body.style.backgroundColor = '';
    }
  }, [gameStatus, winner]);

  return (
    <div>
      <h1>Battle Royale Game</h1>
      <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        {gameStatus === 'Waiting' ? `Prepare for round ${round}!` : gameStatus === 'Running' ? 'Game in progress...' : 'Game over!'}
      </motion.p>
      <div>
        <label htmlFor="players">Number of Players: </label>
        <motion.input 
          type="number" 
          id="players" 
          value={players} 
          onChange={handlePlayersChange} 
          disabled={gameStatus === 'Running'}
          whileHover={{ scale: 1.1 }}
        />
      </div>
      <motion.div 
        className="game-board" 
        initial={{ scale: 0.9 }} 
        animate={{ scale: 1 }} 
        transition={{ duration: 0.5 }}
      >
        <h2>Game Board</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px' }}>
          {Array.from({ length: players }).map((_, index) => (
            <motion.div
              key={index}
              className="player"
              whileHover={{ scale: 1.1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}>
              Player {positions[index]}
            </motion.div>
          ))}
        </div>
      </motion.div>
      <motion.button
        onClick={startGame}
        disabled={gameStatus === 'Running'}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}>
        Start Game
      </motion.button>
      {gameStatus === 'Finished' && winner && (
        <motion.h1
          animate={{ scale: [1, 1.5, 1], color: ['#f00', '#0f0', '#00f', '#ff0', '#0ff', '#f0f'] }}
          transition={{ duration: 1, repeat: Infinity }}
          className="winner">
          {winner} Wins!
        </motion.h1>
      )}
      <div>
        <h2>Scores</h2>
        <ul className="scores">
          {Object.entries(scores).map(([player, score]) => (
            <li key={player}>{player}: {score}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BattleRoyale;
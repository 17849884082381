import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item"><Link className="navbar-link" to="/white-anteaters">White Anteater Info</Link></li>
        <li className="navbar-item"><Link className="navbar-link" to="/battle-royale">Battle Royale Game</Link></li>
        <li className="navbar-item"><Link className="navbar-link" to="/n64-collection">N64 Games Collection</Link></li>
        <li className="navbar-item"><Link className="navbar-link" to="/tetris-game">Tetris Game</Link></li>
      </ul>
    </nav>
  );
};

export default NavBar;

import React, { useEffect, useRef, useCallback } from 'react';
import * as THREE from 'three';
import { BoxGeometry, MeshBasicMaterial, Mesh } from 'three';
import './ThreeJsTetris.css';

const shapes = [
  [
    [1, 1, 1, 1],
  ], // I
  [
    [1, 1],
    [1, 1],
  ], // O
  [
    [0, 1, 0],
    [1, 1, 1],
  ], // T
  [
    [0, 1, 1],
    [1, 1, 0],
  ], // Z
  [
    [1, 1, 0],
    [0, 1, 1],
  ], // S
  [
    [1, 0, 0],
    [1, 1, 1],
  ], // L
  [
    [0, 0, 1],
    [1, 1, 1],
  ], // J
];

const createShape = (typeIndex: number) => {
  const shape = shapes[typeIndex];
  const group = new THREE.Group();

  shape.forEach((row, i) => {
    row.forEach((cell, j) => {
      if (cell === 1) {
        const geometry = new BoxGeometry(1, 1, 1);
        const material = new MeshBasicMaterial({ color: Math.random() * 0xffffff });
        const cube = new Mesh(geometry, material);
        cube.position.set(j, -i, 0);
        group.add(cube);
      }
    });
  });

  return group;
};

const ThreeJsTetris: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const dropIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const shapeRef = useRef<THREE.Group | null>(null);

  const initializeThree = useCallback(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    const canvas = renderer.domElement;
    canvas.className = "canvas-style";
    return { scene, camera, renderer, canvas };
  }, []);

  const spawnNewShape = (scene: THREE.Scene) => {
    const shapeIndex = Math.floor(Math.random() * shapes.length);
    const newShape = createShape(shapeIndex);
    newShape.position.set(4, 20, 0); // Starting position at the top of the canvas
    scene.add(newShape);
    shapeRef.current = newShape;
  };

  useEffect(() => {
    if (mountRef.current) {
      const { scene, camera, renderer, canvas } = initializeThree();
      const mount = mountRef.current;
      mount.appendChild(canvas);

      camera.position.z = 10;

      spawnNewShape(scene);

      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();

      const handleKeyDown = (event: KeyboardEvent) => {
        if (shapeRef.current) {
          const newPos = shapeRef.current.position.clone();
          const newShape = shapeRef.current.clone();

          switch (event.key) {
            case 'ArrowLeft':
              newPos.x -= 1;
              break;
            case 'ArrowRight':
              newPos.x += 1;
              break;
            case 'ArrowDown':
              newPos.y -= 1;
              break;
            case 'ArrowUp':
              newShape.rotation.z += Math.PI / 2;
              break;
          }

          // Simple bounds check, replace with a more robust check for collision or out of bounds
          if (newPos.x >= 0 && newPos.x < 10 && newPos.y >= 0) {
            shapeRef.current.position.copy(newPos);
            shapeRef.current.rotation.copy(newShape.rotation);
          }
        }
      };

      dropIntervalRef.current = setInterval(() => {
        if (shapeRef.current) {
          shapeRef.current.position.y -= 1;
          if (shapeRef.current.position.y < 0) {
            scene.remove(shapeRef.current);
            spawnNewShape(scene);
          }
        }
      }, 1000);

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        mount.removeChild(canvas);
        if (dropIntervalRef.current) {
          clearInterval(dropIntervalRef.current);
        }
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [initializeThree]);

  return (
    <div ref={mountRef} className='tetris-container'>
      <h2>Tetris Game Instructions:</h2>
      <p>Use the arrow keys to move and rotate the Tetris blocks.</p>
      <ul>
        <li><strong>Left Arrow:</strong> Move block left</li>
        <li><strong>Right Arrow:</strong> Move block right</li>
        <li><strong>Down Arrow:</strong> Accelerate block downward</li>
        <li><strong>Up Arrow:</strong> Rotate block clockwise</li>
      </ul>
    </div>
  );
};

export default ThreeJsTetris;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WhiteAnteaterInfo from './components/WhiteAnteaterInfo';
import BattleRoyale from './components/BattleRoyale';
import N64GamesCollection from './components/N64GamesCollection';
import ThreeJsTetris from './components/ThreeJsTetris';
import NavBar from './components/NavBar';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <NavBar />
        </header>
        <main className="main-content">
          <Routes>
            <Route path="/white-anteaters" element={<WhiteAnteaterInfo />} />
            <Route path="/battle-royale" element={<BattleRoyale />} />
            <Route path="/n64-collection" element={<N64GamesCollection />} />
            <Route path="/tetris-game" element={<ThreeJsTetris />} />
          </Routes>
        </main>
        <footer>
          <p>&copy; 2023 - White Anteaters Fanpage</p>
        </footer>
      </div>
    </Router>
  );
}
export default App;
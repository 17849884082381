import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './N64GamesCollection.css';

const N64GamesCollection = () => {
  const [games, setGames] = useState([
    { name: 'Super Mario 64', description: 'A platform game where you control Mario as he explores Princess Peach\'s castle.', imageUrl: 'https://example.com/super-mario-64.jpg' },
    { name: 'The Legend of Zelda: Ocarina of Time', description: 'An action-adventure game where you control Link in his quest to stop Ganondorf.', imageUrl: 'https://example.com/zelda-ocarina.jpg' },
    { name: 'Mario Kart 64', description: 'A racing game where you control characters from the Mario universe.', imageUrl: 'https://example.com/mario-kart-64.jpg' },
    { name: 'GoldenEye 007', description: 'A first-person shooter game where you play as James Bond.', imageUrl: 'https://example.com/goldeneye-007.jpg' },
    { name: 'Donkey Kong 64', description: 'A platform game where you control Donkey Kong and his friends to stop King K. Rool.', imageUrl: 'https://example.com/donkey-kong-64.jpg' },
  ]);

  const addGame = (name: string, description: string, imageUrl: string) => {
    setGames([...games, { name, description, imageUrl }]);
  };

  return (
    <div>
      <h1>N64 Games Collection</h1>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {games.map((game, index) => (
          <motion.div
            key={index}
            className="game-card"
            whileHover={{ scale: 1.1 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}>
            <h2>{game.name}</h2>
            <img src={game.imageUrl} alt={`${game.name} cover`} className="game-image" />
            <p>{game.description}</p>
          </motion.div>
        ))}
      </div>
      <input
        type="text"
        className="add-game-input"
        placeholder="Add a new game"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const target = e.target as HTMLInputElement;
            const [name, description, imageUrl] = target.value.split(',');
            addGame(name.trim(), description.trim(), imageUrl.trim());
            target.value = '';
          }
        }}
      />
      <p className="format-info">Use the format: Name, Description, ImageURL</p>
    </div>
  );
};

export default N64GamesCollection;

import React from 'react';
import { motion } from 'framer-motion';
import WhiteAnteaterQuiz from './WhiteAnteaterQuiz';
import './WhiteAnteaterInfo.css';
const WhiteAnteaterInfo: React.FC = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <h1>Information about White Anteaters</h1>
      <p>White anteaters, also known as silver anteaters, are a rare color morph of the giant anteater.</p>
      <h2>Habitat</h2>
      <p>They are typically found in forests and grasslands in Central and South America.</p>
      <h2>Diet</h2>
      <p>White anteaters primarily feed on ants and termites using their long, sticky tongues.</p>
      <h2>Behavior</h2>
      <p>They are solitary animals, and are mostly active during the night.</p>
      <h2>Conservation Status</h2>
      <p>White anteaters are considered vulnerable due to habitat loss and being hunted for their unique appearance.</p>
      <WhiteAnteaterQuiz />
    </motion.div>
  );
};
export default WhiteAnteaterInfo;